import React from 'react'
import ReactDOM from 'react-dom'
import AuthProvider from '@gartnerstudios/p3-components/AuthProvider'
import App from './components/App'

const config = () => ({
    baseUrl: process.env.REACT_APP_BASE_URL,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    baseAuthUrl: process.env.REACT_APP_AUTH_BASE_URL,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
    logoutUri: process.env.REACT_APP_AUTH_LOGOUT_URI,
})

/**
 * Initializes the application
 *
 * NOTE: This function is asynchronous because we do not want to render the
 * application until we have authenticated the user, which itself is an
 * asynchronous process.
 *
 * @return Promise<undefined>
 */
async function initializeApp() {
    const {baseUrl, clientId, baseAuthUrl, redirectUri, logoutUri} = config()

    ReactDOM.render(
      <React.StrictMode>
        <AuthProvider
            baseUrl={baseUrl}
            clientId={clientId}
            baseAuthUrl={baseAuthUrl}
            redirectUri={redirectUri}
            logoutUri={logoutUri}
        >
            <App />
        </AuthProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
}

initializeApp()
