import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import AppMenu from '@gartnerstudios/p3-components/AppMenu'
import UserBadge from '@gartnerstudios/p3-components/UserBadge'

const useStyles = makeStyles((theme) => ({
    envBar: {
        backgroundColor:'rgb(196, 49, 49)',
        textAlign:'center',
        height: theme.spacing(4),
    },
    wrapper: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
    },
    container: {
        flexGrow: 1,
        overflow: 'auto',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}))

const HeaderBox = props => (<Box {...props} component="header">{props.children}</Box>)
const LinkPaper = props => (<Paper {...props} component={Link} underline="none">{props.children}</Paper>)
const LinkPaperBox = props => (<Box {...props} component={LinkPaper}>{props.children}</Box>)

export default function App() {
    const classes = useStyles()

    return (
        <React.Fragment>
            <CssBaseline />

            <div className={classes.wrapper}>
                <EnvironmentWarning />

                <Grid component={HeaderBox} mb={1} container justifyContent="space-between">
                    <Grid item />
                    <Grid item>
                        <Grid container>
                            <Grid item component={AppMenu} />
                            <Grid item component={UserBadge} />
                        </Grid>
                    </Grid>
                </Grid>

                <Container component="main" maxWidth="md" className={classes.container}>
                    <MainContent />
                </Container>

                <Box component="footer" mt={4} p={2}>
                    <Copyright />
                </Box>
            </div>
        </React.Fragment>
    )
}

function MainContent() {
    const [services, setServices] = useState([])

    const fetchServices = async () => {
        const response = await fetch('/services.json')
        setServices(await response.json())
    }

    useEffect(() => {
        fetchServices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if ('/' !== window.location.pathname) {
        return (<NotFound />)
    }

    return (
        <React.Fragment>
            <Typography component="h1" variant="h2" color="inherit" noWrap>
                Personalization Product Platform
            </Typography>

            <List component="nav">
                {services.filter(s => s.path !== '/').map((service, i) => (
                    <ListItem key={i} component={LinkPaperBox} mb={2} href={service.path}>
                        <ListItemAvatar>
                            <Avatar>{service.icon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={service.title} secondary={service.description} />
                    </ListItem>
                ))}
            </List>
        </React.Fragment>
    )
}

function NotFound() {
    return (
        <React.Fragment>
            <Typography component="h1" variant="h2" color="inherit" noWrap>
                Not Found
            </Typography>

            <Typography paragraph>
                The content you have requested was not found.
            </Typography>
        </React.Fragment>
    )
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            © Copyright {new Date().getFullYear()} Gartner Studios, LLC
        </Typography>
    )
}

function EnvironmentWarning() {
    const classes = useStyles()
    const isProd = Boolean(process.env.REACT_APP_PROD_ENV) || false

    if (isProd) {
        return ''
    }

    return (
        <Typography className={classes.envBar}>
            !! This is a non-production environment !!
        </Typography>
    )
}
